import type { ApiClientError, ApiError } from '@shopware/api-client'

export const useShopwareError = () => {
  const { t } = useI18n()

  const getErrorMessage = (error: ApiError): string => {
    const params: Record<string, string> = {}
    for (const key in error.meta?.parameters) {
      // sometimes, error keys are wrapped in curly braces like "{{ key }}"
      if (key.startsWith('{{') || key.endsWith('}}')) {
        const slicedKey = key.slice(2, -2).trim()
        // @ts-ignore
        params[slicedKey] = error.meta.parameters[key]
      } else {
        // @ts-ignore
        params[key] = error.meta.parameters[key]
      }
    }
    return t(`error.code.${error.code}`, params)
  }

  const transformError = (error: ApiClientError<any>): string[] => {
    const errors: string[] = []
    error.details.errors.forEach((error: ApiError) => {
      errors.push(getErrorMessage(error))
    })
    return errors
  }

  const getInvalidFields = (
    error: ApiClientError<any>
  ): Record<string, string> => {
    const invalidFields: Record<string, string> = {}
    error.details.errors.forEach((error: ApiError) => {
      if (error.source && error.source.pointer) {
        const field = error.source.pointer.replace('/', '')
        invalidFields[field] = getErrorMessage(error)
      }
    })
    return invalidFields
  }

  return {
    transformError,
    getInvalidFields,
  }
}
